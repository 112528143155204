import LoadingScreen from 'components/LoadingScreen';
import useUserAccountState from 'hooks/useUserAccountState';
import { ReactNode, useEffect } from 'react';
import { StatsigProvider, useClientAsyncInit } from '@statsig/react-bindings';
import { StatsigUser } from '@statsig/client-core';

type StatsigProviderProps = {
  children: ReactNode;
  sdkKey: string;
};

export function EnhancedStatsigProvider({
  children,
  sdkKey,
}: StatsigProviderProps) {
  const {
    user,
    account,
    currentOrganization: organization,
  } = useUserAccountState();

  let userEmail = user.email;
  // Check if the app is running on localhost
  const isLocalhost = window.location.hostname === 'localhost';
  const StatsigOptions = {
    networkConfig: {
      api: `${window.location.origin}/featuregates`,
      eventLoggingApi: `${window.location.origin}/featuregates/events`,
    },
  };

  const options = isLocalhost
    ? {} // No options on localhost
    : StatsigOptions;

  const userObj: StatsigUser = {
    userID: userEmail,
    email: userEmail,
    custom: {
      name: user.name,
      website: location.host,
      accountId: account?.accountId,
      orgId: organization?.id,
    },
  };

  if (!sdkKey && !userObj.email) {
    return <>{children}</>;
  }

  const { client, isLoading } = useClientAsyncInit(sdkKey, userObj, options);

  useEffect(() => {
    if (userObj.email) {
      client.updateUserSync(userObj);
    }
  }, [userObj]);

  return isLoading ? (
    <LoadingScreen></LoadingScreen>
  ) : (
    <StatsigProvider client={client} user={userObj} options={options}>
      {children}
    </StatsigProvider>
  );
}
